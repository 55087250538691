import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hydrology`}</h1>
    <h2>{`Hydrology Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PMWE0RzVRNmdXTHc"
      }}>{`Download: Engineering Hydrology by K Subramanya 3rd ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PSHlBM2IyYzBJWFU"
      }}>{`Download: Solution Manual Engineering Hydrology by K Subramanya 3rd ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PVzAxMEVmUlFSTUE"
      }}>{`Download: Surface water hydrology by N.M. Awan`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PQ0l6cWdWcHl0UWc"
      }}>{`Download: Applied hydrology by Ven Te Chow, David R. Maidment and Larry W. Mays`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PZy1BdlBGaVJGdTg"
      }}>{`Download: Concise Hydrology by Dawei Han`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PbFhkaVZnREtXWGM"
      }}>{`Download: Engineering Hydrology by Yilma Seleshi`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PRWNkcHpBSWtHT0k"
      }}>{`Download: Engineering Hydrology by Dr. Aqeel Al Adili`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1-xgvWnezHqANa0UhzPhbKcgBddg4stAg"
      }}>{`Download: Essentials of Meteorology An invitation to atmosphere by C. Donald Ahrens 3rd ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1OKZQ7DiV1ejP1kOql6PpqSmz3hPTAYGW"
      }}>{`Download: Handbook of hydrology by David R. Maidment `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PVk1fckpuS0JzbU0"
      }}>{`Download: Hydrology for engineers by Ray K. Linsley, Max A. Kohler and Joseph L.H. Paulhus `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1b2eiRBeSVUmbgvplMWGv_-XSOd1Var8d"
      }}>{`Download: Hydrology Principles, Analysis and Design by H.M. Raghunath`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PczJ0cWJtb2s1MDQ"
      }}>{`Download: Introduction to hydrology by Warren Viesmann and Gary L. Lewis `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1GytzT7o22gH9kcxCotubxeGoYQbAPtjo"
      }}>{`Download: Manual on estimation of probable maximum precipitation by WMO`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1iuToRop8cHjdscLqEi79sZO31T2hmH6Q"
      }}>{`Download: Technical guide River and stream systems: Flooding Hazard limit Ontario`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1RrWo4oEG47lI_M8cljvOhMfQp4l3hz89"
      }}>{`Download: Flood hydrology manual A water resources technical publication by USBR `}</a></p>
    <h2>{`Engineering Hydrology Lectures`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-1-introduction.pptx"
      }}>{`Lecture 1 Introduction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-3-precipitation.pptx"
      }}>{`Lecture 3 Precipitation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-4-measurement-of-precipition.pptx"
      }}>{`Lecture 4 Measurement of precipitation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-5-precipitation-gauge-network.pptx"
      }}>{`Lecture 5 Precipitation gauge network`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-6-RADAR-AND-SATELLITE-ESTIMATION-OF-PRECIPITATION.pptx"
      }}>{`Lecture 6 RADAR AND SATELLITE ESTIMATION OF PRECIPITATION`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-7-evaporation.pptx"
      }}>{`Lecture 7 Evaporation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-8-STREAM-GAUGING.pptx"
      }}>{`Lecture 8 STREAM GAUGING`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-9-stream-gauging-discharge-measurement.pptx"
      }}>{`Lecture 9 Stream gauging discharge measurement`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-10-runoff.pptx"
      }}>{`Lecture 10 Runoff`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-11-hydrographs.pptx"
      }}>{`Lecture 11 Hydrographs`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-12-floods-and-their-estimation.pptx"
      }}>{`Lecture 12 Floods and their estimation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-13-flood-frequency-analysis.pptx"
      }}>{`Lecture 13 Flood frequency analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-14-flood-routing.pptx"
      }}>{`Lecture 14 Flood routing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Lecture-15-ground-water-hydrology.pptx"
      }}>{`Lecture 15 Ground water hydrology`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/selection-of-rain-snow-gauge.pdf"
      }}>{`Selection of rain & snow gauge`}</a></p>
    <h3>{`Assignments:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Assignments-and-solutions.pdf"
      }}>{`Assignments and solutions`}</a></p>
    <h2><a parentName="h2" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/engineering-hydrology-lab-manual/"
      }}>{`Engineering hydrology lab manual`}</a></h2>
    <h2>{`Engineering Hydrology important notes`}</h2>
    <h3>{`Excel sheets:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/conversion-of-UHG-to-another-UHG.xlsx"
      }}>{`conversion of UHG to another UHG`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/DETERMINATION-OF-MUSKINGUM%E2%80%99S-CONSTANTS.xlsx"
      }}>{`DETERMINATION OF MUSKINGUM’S CONSTANTS`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/FLOOD-FREQUENCY-analysis.xlsx"
      }}>{`FLOOD FREQUENCY analysis`}</a></p>
    <h3>{`Notes:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Areal-Estimation-of-Precipitation.pdf"
      }}>{`Areal Estimation of Precipitation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Computation-of-Average-Rainfall-over-a-Basin.pdf"
      }}>{`Computation of Average Rainfall over a Basin`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Development-of-Pakistan%E2%80%99s-New-Area-Weighted-Rainfall-Using-Thiessen-Polygon-Method.pdf"
      }}>{`Development of Pakistan’s New Area Weighted Rainfall Using Thiessen Polygon Method`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Thiessen-polygon-method.pdf"
      }}>{`Thiessen polygon method`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/current-meter.pdf"
      }}>{`current meter`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/evaporation-and-its-measurement.pdf"
      }}>{`evaporation and its measurement`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Flood-Routing-in-Natural-Channels-Using-Muskingum-Methods.pdf"
      }}>{`Flood Routing in Natural Channels Using Muskingum Methods`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/metrological-instruments-1.pdf"
      }}>{`meteorological instruments 1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/metrological-instruments.pdf"
      }}>{`meteorological instruments`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/muskingum-method.pdf"
      }}>{`Muskingum method`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      